import React, { useState, useEffect, useRef } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import slugify from "slugify"
import * as styles from "./styles.module.less"

// load all locations from sitemap in contentful at build time,
// pass to location menu to display
const LocationsProxy = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulSiteMap(filter: { title: { eq: "Northwest" } }) {
            edges {
              node {
                locations {
                  locationTitle
                }
              }
            }
          }
        }
      `}
      render={(data) => <LocationsDropdown data={data} />}
    />
  )
}

const LocationsDropdown = ({ data }) => {
  // destructure gatsby data
  const ref = useRef(null)
  const locations = data?.allContentfulSiteMap?.edges[0].node.locations || []
  const [locationsDropdownOpen, setLocationsDropdownOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setLocationsDropdownOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [locationsDropdownOpen])

  return (
    <button
      ref={ref}
      onClick={() => setLocationsDropdownOpen(!locationsDropdownOpen)}
    >
      Locations
      <svg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L6.21739 6L11 1"
          stroke="#0078B3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {locationsDropdownOpen && (
        <ul className={styles.locationsMenu}>
          {locations.map((location) => {
            const { locationTitle } = location
            const slug = `/${slugify(locationTitle)}`
            return (
              <li>
                <Link to={slug}>{locationTitle}</Link>
              </li>
            )
          })}
        </ul>
      )}
    </button>
  )
}

export default LocationsProxy
