import React from "react"

const KpojLogo = ({ blue }) => (
  <svg
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 287 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8,6.4c-.2-.3-.5-.4-.8-.5l-.5,8.9,2.2-6.1c.3-.9-.2-1.8-.9-2.3Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M9.1,5.9l1.3,8.8,1.3-8.8c-.8-.3-1.7-.3-2.6,0Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M8,6.4c-.7,.5-1.1,1.4-1,2.3l2.2,6.1-.5-8.9c-.2,.1-.4,.3-.7,.5Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M3.6,7.2c1.5,2.5,3,5.3,4.4,7.9-.5-2.2-1-5.8-1.9-6.8-.8-1.2-2.5-1.1-2.5-1.1Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M.9,8.6l6.1,6.9L3.2,7.3c-.9,0-1.8,.5-2.3,1.3Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M13.6,10.4l-1,4.7c1.3-2.2,2.6-4.4,3.8-6.7-1.2,.2-2.4,.6-2.8,2Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M16.8,8.6l-3.1,7.1,5.6-6.1c-.7-.8-1.6-1-2.5-1Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M.1,11.7c2,1.5,4,3.1,5.9,4.6L.7,8.8c-.5,.8-.6,1.8-.6,2.9Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M19.9,10.4c-.1-.2-.2-.5-.5-.7l-4.9,6.6c1.9-1.4,3.7-2.9,5.6-4.3,.2-.5,0-1.1-.2-1.6Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M.2,14.7l5.1,2.3c-1.7-1.7-3.5-3.4-5.1-5v2.7Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M20.3,14.9v-2.4l-4.9,4.7c1.6-.8,3.1-1.5,4.9-2.3Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M.1,17.1c1.5,.3,3.3,.8,4.7,1L.1,15.1v2Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M20.3,17.2v-1.9c-1.5,.9-3,1.9-4.3,2.9l4.3-1Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path d="M.1,19.4H4.6L.1,17.4v2Z" fill={blue ? `#003b71` : `#fff`} />
    <path
      d="M20.3,19.4v-1.7c-1.5,.7-2.8,1.2-4.2,1.7h4.2Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M18.6,6.1c0-2.7-4-2.8-3.8,0,0,2.6,3.9,2.7,3.8,0Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M5.7,4.7c.1-2.7-4.1-2.7-4,0-.1,2.8,4.2,2.8,4,0Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M10.5,5.3c1.5,0,2.2-1.2,2.2-2.2,.1-1.6-.9-2.4-2.2-2.4s-2.3,.7-2.2,2.4c0,.9,.3,2.1,2.2,2.2Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path d="M45.6,8.7h-1.9v10.6h1.9V8.7Z" fill={blue ? `#003b71` : `#fff`} />
    <path
      d="M36.9,14.7l1.4-4.4,1.5,4.4h-2.9Zm.4-6l-3.1,9-3-3.8,4.1-5.2h-2.1l-3.7,5.1v-5.1h-1.9v10.6h1.9v-5.2l4,5.2h2.4l1-3.1h4l1,3h2l-4-10.6h-2.7v.1h.1Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M105.6,16.3l-1.9-7.8h-3.3v10.6h1.9V10.1l2.3,9h1.9l2.3-9v9h1.7V8.6h-3.1l-1.8,7.7Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M114.8,14.7l1.4-4.4,1.5,4.4h-2.9Zm.5-6l-3.7,10.6h1.9l1-3.1h4l1,3.1h2l-3.7-10.6h-2.5Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M128.8,16.9l-4.3-8.2h-2.5l.1,10.6h1.7V11.2l4.2,8.1h2.4V8.7h-1.7v8.2h.1Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M163.7,17.7h-4.4v-3h3.3v-1.5h-3.3v-3h4v-1.5h-5.8v10.6h6.2v-1.6Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M147,8.7h-.4v8.3l-4.2-8.3h-2.4v9h-5.6v-3h3.3v-1.5h-3.3v-3h4v-1.5h-5.8v10.6h9.2V11.2l4.2,8.1h2.6V10.3h3.3v9h1.9V10.3h3.1v-1.6h-9.9Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M93.5,13.2v-3h1.5c1.4,0,2,.6,2,1.5,0,1-.6,1.5-2,1.5h-1.5Zm4.3,.7c.6-.5,1-1.3,1-2.3s-.5-1.7-1-2.2c-.7-.6-1.5-.8-3.1-.8h-3v9.1h-5.6v-3h3.3v-1.5h-3.3v-3h4.1v-1.5h-5.8v10.6h9.3v-4.5h.9c.8,1.3,2.7,4.5,2.7,4.5h2.2l-2.9-4.6c.4-.2,.9-.5,1.2-.8Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M64.4,13.2v-3h1.5c1.4,0,2,.6,2,1.5,0,1-.6,1.5-2,1.5h-1.5Zm4.3,.7c.6-.5,1-1.3,1-2.3s-.5-1.7-1-2.2c-.7-.6-1.5-.8-3.1-.8h-3v9.1h-5.6v-3h3.3v-1.5h-3.3v-3h4v-1.5h-5.8v10.6h9.3v-4.5h.9c.8,1.3,2.7,4.5,2.7,4.5h2.2l-2.9-4.6c.5-.2,1-.5,1.3-.8Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M79.3,13.3h-1.6v-3.1h1.5c1.4,0,2,.6,2,1.5,.1,1-.6,1.6-1.9,1.6Zm2.8-3.8c-.7-.6-1.5-.8-3.1-.8h-3.1v10.6h1.9v-4.4h1.2c1.5,0,2.4-.2,3-.8,.6-.5,1-1.4,1-2.3,0-1-.3-1.8-.9-2.3Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M51.1,13.1c-1.4-.6-2.1-.9-2.1-1.7,0-.7,.7-1.4,2-1.4,.9,0,1.9,.3,2.3,.6v-1.6c-.5-.2-1.4-.5-2.2-.5-2.3,0-3.8,1.3-3.8,3,0,1.5,1.2,2.4,3,3.1,1.4,.5,2,.9,2,1.9s-.8,1.5-2.1,1.5c-1,0-2.2-.3-2.9-.8v1.6c.6,.3,1.7,.6,2.8,.6,2.8,0,4.1-1.5,4.1-3.1-.3-1.6-1.2-2.5-3.1-3.2Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M277.1,13.8c0,1.7-1,3.7-3.3,3.7-2.1,0-3.4-1.9-3.4-3.7,0-1.7,1.3-3.7,3.4-3.7,2.1,.1,3.3,1.9,3.3,3.7Zm-9.6-10.1v15.9h2.8v-1.7c.8,1.3,2.3,2.1,4,2.1,3.5,0,5.7-2.8,5.7-6.1s-2.3-6-5.6-6c-2.1,0-3.3,1-3.8,1.7h-.1V3.6h-2.9v.1h-.1Zm-12,10.1c0-1.7,1-3.7,3.4-3.7,2.3,0,3.4,1.9,3.4,3.7,0,1.7-1,3.7-3.4,3.7-2.3,.1-3.4-1.9-3.4-3.7Zm-2.9,0c0,3.6,2.8,6.1,6.3,6.1s6.3-2.5,6.3-6.1-2.8-6-6.3-6c-3.5,0-6.3,2.4-6.3,6Zm-5.7-9.5c0,.9,.8,1.7,1.9,1.7,1,0,1.9-.7,1.9-1.7,0-.9-.8-1.7-1.9-1.7-1.1-.1-1.9,.8-1.9,1.7Zm.4,3.8v12.3c0,1.3-.2,2.4-1.5,2.4-.2,0-.6,0-.8-.1l-.2,2.4c.2,.1,.8,.1,1.3,.1,3.5,0,4.2-2.3,4.2-5.1V8.1h-3Zm-2.1,4.4h-4.1v2.4h4.1v-2.4Zm-6.2,1.5c0-4.2-2.3-6.2-5.6-6.2-3.5,0-6.1,2.4-6.1,6.1s2.7,6,6.2,6c2.2,0,3.8-.8,5-2.3l-2-1.5c-.7,.9-1.6,1.5-3,1.5-1.6,0-3.1-1.2-3.3-2.8h8.7c.1-.3,.1-.6,.1-.8Zm-8.9-1.3c.1-1.6,1.5-2.8,3.1-2.8,1.9,0,2.7,1.3,2.7,2.8h-5.8Zm-9.4-4.9c-1.7,0-3,.9-3.5,2V3.8h-2.9v15.9h2.9v-6.2c0-1.5,.9-3.1,2.7-3.1,1.9,0,2.1,1.6,2.1,3v6.4h2.9v-7.2c.1-2.5-1.2-4.8-4.2-4.8Zm-14.7,2.6v5.3c0,2.2,.7,4,3.7,4,.7,0,1.4-.1,2-.3l-.1-2.2c-.3,.1-.8,.2-1.3,.2-1.3,0-1.5-.7-1.5-1.7v-5.3h2.9v-2.3h-2.9v-3.4h-2.8v3.4h-2v2.3h2Zm-3,2.1h-4.2v2.4h4.1v-2.4h.1Zm-16.9,7.1h2.9v-6.4c0-1.5,.8-3.1,2.7-3.1s2.2,1.7,2.2,3v6.5h2.9v-7.2c0-2.3-1.3-4.6-4.3-4.6-1.7,0-3,1-3.6,2.2v-1.9h-2.8v11.5h0Zm-11.4-5.8c0-1.7,1-3.7,3.4-3.7,2.3,0,3.4,1.9,3.4,3.7,0,1.7-1,3.7-3.4,3.7s-3.4-1.9-3.4-3.7Zm-3,0c0,3.6,2.8,6.1,6.3,6.1s6.3-2.5,6.3-6.1-2.8-6-6.3-6c-3.5,0-6.3,2.4-6.3,6Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M34.6,38.1l-6.2-7.9h-1v9.8h1.1v-8l6.3,8h.9v-9.8h-1.1v7.9Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M45.5,36.4c0-2.1-1.6-3.8-3.8-3.8s-3.8,1.7-3.8,3.8,1.6,3.8,3.8,3.8,3.8-1.8,3.8-3.8Zm-1.1,0c0,1.6-1.1,2.8-2.7,2.8-1.5,0-2.7-1.3-2.7-2.8s1.1-2.8,2.6-2.8c1.6,0,2.8,1.2,2.8,2.8Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M48.6,37.1c0-2.2,1.3-3.3,2.9-3.3h.1v-1.2c-1.4-.1-2.4,.8-3,2v-1.9h-1.1v7.3h1.1v-2.9Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M54.7,33.7h2.3v-1h-2.3v-2.2h-1.1v2.2h-1v1h1v4.4c0,1.5,.9,2,2,2,.5,0,.9-.1,1.3-.3v-.9c-.4,.2-.7,.3-1.1,.3-.7,0-1.2-.3-1.2-1.2v-4.3h.1Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M60.1,35.9c0-1.4,.9-2.3,2.2-2.3s2,.8,2,2.2v4.2h1.1v-4.5c0-1.7-1-2.9-2.8-2.9-1.2,0-2,.6-2.5,1.4v-4.2h-1.1v10.2h1.1v-4.1Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M73.1,32.7h-.9l-1.9,5.8-1.9-5.8h-1.1l2.5,7.3h1l2-5.7,1.9,5.7h.9l2.5-7.3h-1.1l-2,5.9-1.9-5.9Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M80.6,36.8h5.7v-.3c0-2.1-1.2-3.8-3.4-3.8-2,0-3.5,1.7-3.5,3.8,0,2.3,1.6,3.8,3.6,3.8,1.4,0,2.3-.5,3-1.4l-.7-.6c-.6,.6-1.3,1-2.3,1-1.1-.1-2.3-1-2.4-2.5Zm0-.8c.1-1.4,1.1-2.5,2.4-2.5,1.4,0,2.2,1.1,2.3,2.5h-4.7Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M93.3,38h0c0-1.3-1.2-1.8-2.3-2.1-1-.3-1.9-.6-1.9-1.2h0c0-.6,.5-1,1.4-1,.7,0,1.5,.3,2.1,.7l.5-.8c-.7-.5-1.7-.8-2.6-.8-1.4,0-2.5,.8-2.5,2.1h0c0,1.3,1.3,1.7,2.4,2,1,.3,1.8,.5,1.8,1.3h0c0,.7-.6,1.1-1.5,1.1s-1.7-.3-2.5-.9l-.5,.8c.8,.7,2,1.1,3,1.1,1.5-.2,2.6-1,2.6-2.3Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M96.8,33.7h2.3v-1h-2.3v-2.2h-1.1v2.2h-1v1h1v4.4c0,1.5,.9,2,2,2,.5,0,.9-.1,1.3-.3v-.9c-.4,.2-.7,.3-1.1,.3-.7,0-1.2-.3-1.2-1.2v-4.3h.1Z"
      fill={blue ? `#003b71` : `#fff`}
    />
    <path
      d="M285.8,6.7c0,1.3-1.1,2.4-2.4,2.4-1.4,0-2.5-1.1-2.5-2.4s1.1-2.4,2.5-2.4c1.3,0,2.4,1,2.4,2.4Zm-4.3,0c0,1.1,.8,1.9,1.9,1.9,1,0,1.8-.8,1.8-1.9s-.8-1.9-1.8-1.9c-1.1,0-1.9,.8-1.9,1.9Zm1.5,1.2h-.6v-2.4c.2,0,.5-.1,.9-.1,.5,0,.7,.1,.8,.2,.1,.1,.2,.3,.2,.5,0,.3-.2,.5-.5,.6h0c.2,.1,.4,.3,.4,.6,.1,.4,.1,.5,.2,.6h-.6c-.1-.1-.1-.3-.2-.6,0-.3-.2-.4-.5-.4h-.1v1Zm0-1.3h.3c.3,0,.6-.1,.6-.3s-.2-.4-.5-.4h-.3v.7h-.1Z"
      fill={blue ? `#003b71` : `#fff`}
    />
  </svg>
)

export default KpojLogo
