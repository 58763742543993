import React from "react"
import * as styles from "./styles.module.less"

const Hero = ({ eyebrow, headline, image, alt }) => {
  return (
    <section className={styles.hero}>
      <div className={styles.heroContent}>
        <div className={styles.blueBackground}></div>
        <div className={styles.text}>
          {eyebrow && <h3 className={styles.eyebrow}>{eyebrow}</h3>}
          <h1>{headline}</h1>
        </div>
        <div className={styles.heroImage}>
          <img src={image} alt={alt} />
        </div>
      </div>
    </section>
  )
}

export default Hero
