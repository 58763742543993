import React, { useState } from "react"
import { Link } from "gatsby"
import KpojLogo from "../../../KpojLogo"
import Cta from "../../../Cta"
import * as styles from "./styles.module.less"

const MobileMenu = ({ clickHandler }) => {
  const [mobileMenu, setMobileMenu] = useState(false)
  return (
    <div className={styles.mobileMenu}>
      <div className={styles.topPart}>
        <div className={styles.kpojLogo}>
          <Link to="/">
            <KpojLogo />
          </Link>
        </div>
        <button onClick={clickHandler}>&times;</button>
      </div>

      <ul className={styles.navigation}>
        <li>
          <Link to="/services">Services</Link>
        </li>
        <li>
          <Link to="/employers">Employers</Link>
        </li>
        <li>
          <Link to="/patients">Patients</Link>
        </li>
        <li className={styles.locationMenuButton}>
          <button to="/" onClick={() => setMobileMenu(!mobileMenu)}>
            Locations{" "}
            <svg
              width="17"
              height="11"
              viewBox="0 0 17 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={mobileMenu ? undefined : styles.disclosureArrow}
            >
              <path
                d="M1 1.50208L8.37705 9.50208L16 1.50208"
                stroke="#92CCF0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <ul
            className={
              mobileMenu
                ? `${styles.mobileLocationMenu}`
                : `${styles.mobileLocationMenu} ${styles.hidden}`
            }
          >
            <li>
              <Link to="/Portland">Portland</Link>
            </li>
            <li>
              <Link to="/Beaverton">Beaverton</Link>
            </li>
            <li>
              <Link to="/Vancouver">Vancouver</Link>
            </li>
            <li>
              <Link to="/Salem">Salem</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      {/* <div className={styles.cta}>
        <Cta text="OHSS log in &#8594;" link="#" />
      </div> */}
      <div className={styles.cta}>
        <Cta text="kp.org &#8594;" link="https://www.kp.org" type="bordered" />
      </div>
    </div>
  )
}

export default MobileMenu
