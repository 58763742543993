import React from "react"
import * as styles from "./styles.module.less"

const Banner = ({ children }) => (
  <div className={styles.banner}>
    <div className={styles.container}>{children}</div>
  </div>
)

export default Banner
