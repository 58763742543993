import React, { useState } from "react"
import { Link } from "gatsby"
import KpojLogo from "../KpojLogo"
import Cta from "../Cta"
import LocationsDropdown from "./components/LocationsDropdown"
import MobileMenu from "./components/MobileMenu"
import Hamburger from "./hamburger"
import * as styles from "./styles.module.less"

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <nav>
      <Link to="/">
        <div className={styles.logo}>
          <KpojLogo blue />
        </div>
      </Link>
      <div className={styles.desktopNav}>
        <div className={styles.internalLinks}>
          <ul>
            <li className={styles.navItem}>
              <Link to="/services">Services</Link>
            </li>
            <li className={styles.navItem}>
              <Link to="/employers">Employers</Link>
            </li>
            <li className={styles.navItem}>
              <Link to="/patients">Patients</Link>
            </li>
            <li className={styles.navItem}>
              <LocationsDropdown />
            </li>
            <li className={styles.navItem}>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.externalLinks}>
          <Cta text="kp.org →" type="bordered" link="https://www.kp.org" />
          {/* <Cta text="OHSS log in →" /> */}
        </div>
      </div>
      <button
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
        className={styles.hamburger}
      >
        <Hamburger />
      </button>
      {menuOpen && <MobileMenu clickHandler={() => setMenuOpen(!menuOpen)} />}
    </nav>
  )
}

export default Nav
