/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"
import Banner from "../Banner"
import Nav from "../Nav"
import Footer from "./components/Footer"
import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a href={uri} {...attr}>
          {children}
        </a>
      )
    },
  },
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      contentfulBanner {
        id
        bannerContent {
          raw
        }
      }
    }
  `)

  const bannerContent = data.contentfulBanner?.bannerContent

  console.log(bannerContent)

  return (
    <>
      <div className={styles.layout}>
        {bannerContent && (
          <Banner>{renderRichText(bannerContent, renderOptions)}</Banner>
        )}
        <Nav />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
