import React, { useState } from "react"
import { Link } from "gatsby"
import KpojLogo from "../../../KpojLogo"
// import Cta from "../../../Cta"
import * as styles from "./styles.module.less"

const Footer = () => {
  const [showMobileLocationMenu, setShowMobileLocationMenu] = useState(false)

  return (
    <footer className={styles.footer}>
      <div className={styles.kpojLogo}>
        <KpojLogo />
      </div>
      <ul className={styles.navigation}>
        <li>
          <Link to="/services">Services</Link>
        </li>
        <li>
          <Link to="/employers">Employers</Link>
        </li>
        <li>
          <Link to="/patients">Patients</Link>
        </li>
        <li className={styles.locationMenuButton}>
          <button
            to="/"
            onClick={() => setShowMobileLocationMenu(!showMobileLocationMenu)}
          >
            Locations{" "}
            <svg
              width="17"
              height="11"
              viewBox="0 0 17 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={!showMobileLocationMenu && styles.disclosureArrow}
            >
              <path
                d="M1 1.50208L8.37705 9.50208L16 1.50208"
                stroke="#92CCF0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </li>
        <ul
          className={
            showMobileLocationMenu
              ? `${styles.mobileLocationMenu}`
              : `${styles.mobileLocationMenu} ${styles.hidden}`
          }
        >
          <li>
            <Link to="/Portland">Portland</Link>
          </li>
          <li>
            <Link to="/Beaverton">Beaverton</Link>
          </li>
          <li>
            <Link to="/Vancouver">Vancouver</Link>
          </li>
          <li>
            <Link to="/Salem">Salem</Link>
          </li>
        </ul>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      <ul className={styles.desktopLocationMenu}>
        <li>
          <Link to="/Portland">Portland</Link>
        </li>
        <li>
          <Link to="/Beaverton">Beaverton</Link>
        </li>
        <li>
          <Link to="/Vancouver">Vancouver</Link>
        </li>
        <li>
          <Link to="/Salem">Salem</Link>
        </li>
      </ul>
      {/* <div className={styles.cta}>
        <Cta text="OHSS log in &#8594;" link="#" type="white" />
      </div> */}

      <div className={styles.otherSites}>
        Visit our other sites:
        <br />
        <a href="http://kp.org" target="_blank" rel="noreferrer">
          kp.org
        </a>
        <br />
        <a
          href="https://business.kaiserpermanente.org/"
          target="_blank"
          rel="noreferrer"
        >
          For Businesses
        </a>
        <br />
        <a
          href="http://kp.org/occupational-health"
          target="_blank"
          rel="noreferrer"
        >
          National Kaiser Permanente On-the-Job
        </a>
        <br />
        <a
          href="https://kpvr.org/kaiser-permanente-on-the-job/"
          target="_blank"
          rel="noreferrer"
        >
          Virtual Tour
        </a>
        <br />
        <p>
          <a href="javascript:(OneTrust.ToggleInfoDisplay())">
            Your Privacy Choices
          </a>
        </p>
      </div>
      <div className={styles.address}>
        ©2021 Kaiser Foundation Health Plan of the Northwest,
        <br /> 500 NE Multnomah St., Suite 100, Portland, OR 97232
      </div>
    </footer>
  )
}

export default Footer
