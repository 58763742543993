import React from "react"

const Hamburger = () => (
  <svg
    width="27"
    height="19"
    viewBox="0 0 27 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 17.2913C0 18.0968 0.652918 18.7497 1.45833 18.7497H24.7917C25.5971 18.7497 26.25 18.0968 26.25 17.2913C26.25 16.4859 25.5971 15.833 24.7917 15.833H1.45833C0.652918 15.833 0 16.4859 0 17.2913ZM0 9.37484C0 10.1803 0.652918 10.8332 1.45833 10.8332H24.7917C25.5971 10.8332 26.25 10.1803 26.25 9.37484C26.25 8.56942 25.5971 7.9165 24.7917 7.9165H1.45833C0.652918 7.9165 0 8.56942 0 9.37484ZM1.45833 0C0.652919 0 0 0.652918 0 1.45833C0 2.26375 0.652918 2.91667 1.45833 2.91667H24.7917C25.5971 2.91667 26.25 2.26375 26.25 1.45833C26.25 0.652918 25.5971 0 24.7917 0H1.45833Z"
      fill="#003B71"
    />
  </svg>
)

export default Hamburger
