import React from "react"
import PropTypes from "prop-types"
import * as styles from "./styles.module.less"

const Cta = ({ text, link = "", type }) => {
  let additional = {}
  if (link.match(/^https?:\/\//)) {
    additional = {
      target: "_blank",
      rel: "noreferrer",
    }
  }
  switch (type) {
    case "bordered":
      return (
        <a className={styles.ctaBordered} href={link} {...additional}>
          {text}
        </a>
      )
    case "white":
      return (
        <a className={styles.ctaWhite} href={link} {...additional}>
          {text}
        </a>
      )
    default:
      return (
        <a className={styles.cta} href={link} {...additional}>
          {text}
        </a>
      )
  }
}

Cta.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.oneOf(["bordered", "white"]),
}

export default Cta
